import { combineReducers } from 'redux'
import { default as heatMapReducer } from './heatmap'
import { default as executiveSummaryRedure } from './executivesummary'
import { default as bubbleChartReducer } from './bubblechart'
import { default as speedometerReducer } from './speedometer'
import { default as totalImpactChartReducer } from './totalimpact'
import { default as timeLineChartReducer } from './timeline'
import { default as stakeholderChartReducer } from './stakeholder'
import { default as resourcingChartReducer } from './resourcingchart'
import { default as readinessChartReducer } from './readiness'
import { default as strategyImpactChartReducer } from './strategyimpact'
import { default as benefitsChartReducer } from './benefits'
import { default as colorIntensityReducer } from './colorIntensity'
import { default as changeCalendarReducer } from './changeCalendar'

const reportsReducer = combineReducers({
    heatmap: heatMapReducer,
    timeLineReport: timeLineChartReducer,
    executiveSummary: executiveSummaryRedure,
    bubbleChart: bubbleChartReducer,
    speedometer: speedometerReducer,
    totalImpactChart: totalImpactChartReducer,
    stakeholderChart: stakeholderChartReducer,
    resourcingChart: resourcingChartReducer,
    readinessReport: readinessChartReducer,
    strategyImpactChart: strategyImpactChartReducer,
    colorIntensity: colorIntensityReducer,
    benefitsChart: benefitsChartReducer,
    changeCalendar: changeCalendarReducer,
})

export default reportsReducer
