import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { RootState } from '../../store'
import { Dispatch } from 'redux'
import { fetchResponseData, getErrorMessage, getResponseData } from '../../../utils/axios'
import { toServerTime } from '../../../utils/formatDate'
import { ChangeCalendarData, ChangeCalendarItem } from '../../../@types/settings'
import { isSameDay, parseISO } from 'date-fns'
import { findIndex } from 'lodash'

interface initialStateProps {
    isLoading: boolean,
    error: null | string,
    data: ChangeCalendarItem[]
}

const initialState: initialStateProps = {
    isLoading: false,
    error: null,
    data: [],
}

const slice = createSlice({
    name: 'changeCalendar',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setData(state, action) {
            state.data = action.payload
        }
    }
})

export default slice.reducer


export function fetchChangeCalendarData(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.setLoading(true))
        try {
            const data = await fetchResponseData('/api/CompanySettings/GetCalendarReportDates', {
                fromDate: toServerTime(startDate),
                endDate: toServerTime(endDate)
            })
            dispatch(
                slice.actions.setData(
                    (data as ChangeCalendarData[]).map((item: Record<string, any>) => ({
                        ...item,
                        CreatedAt: parseISO(item.CreatedAt),
                        Date: parseISO(item.Date),
                    }))
                ))
        } catch (e) {
            dispatch(slice.actions.setError(getErrorMessage(e)))
            dispatch(slice.actions.setData([]))
        } finally {
            dispatch(slice.actions.setLoading(false))
        }
    }
}

export const getChangeCalendarData = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): ChangeCalendarItem[] => state.data
)

export const isLoadingChangeCalendar = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): boolean => state.isLoading
)

export const getChangeCalendarLoadingError = createSelector(
    (state: RootState) => state.reports.changeCalendar,
    (state): null | string => state.error
)

export function saveChangeCalendarData(items: { date: Date, color: string }[], callback?: (error?: string) => void) {
    return async (dispatch: Dispatch<any>, getState: RootState) => {
        const data = [ ...getState().reports.changeCalendar.data ]
        try {

            const requestData = items.map(({ date, color }) => ({
                Date: toServerTime(date),
                Id: '00000000-0000-0000-0000-000000000000',
                Color: color,
            }))

            const newData = [ ...getState().reports.changeCalendar.data ]
            items.forEach((item) => {
                const index = findIndex(newData, (i: ChangeCalendarItem) => isSameDay(i.Date, item.date))
                const newItem = {
                    Date: item.date,
                    Color: item.color,
                    CreatedAt: new Date(),
                    CreatedBy: '',
                    Id: '00000000-0000-0000-0000-000000000000',
                }
                if (index === -1) {
                    newData.push(newItem)
                } else {
                    newData[index] = newItem
                }
            })

            dispatch(slice.actions.setData([ ...newData ]))

            await getResponseData('/api/CompanySettings/SaveCalendarReportDates', requestData)
            callback && callback()
        } catch (e) {
            dispatch(slice.actions.setError(getErrorMessage(e)))
            callback && callback(getErrorMessage(e))
            dispatch(slice.actions.setData([ ...data ]))
        }
    }
}