import {
    add,
    addDays,
    addMinutes,
    addMonths,
    addYears,
    areIntervalsOverlapping,
    differenceInMonths,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    parseISO,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    getWeekOfMonth, isSameDay,
    parse, addWeeks,
} from 'date-fns'
import { chunk, head, isArray, isString, last } from 'lodash';
import {
    ImpactTiming,
    ImpactTimingRange,
    ImpactTimingRangeResponse,
    ImpactTimingResponse,
    ImpactWeeksInMonth
} from '../@types/impact';
import { DateRange } from 'components/DateRangePicker';
import { formatDate, toServerTime } from 'utils/formatDate';
import { WeeksInMonth } from '../@types/reports';
import { ImpactList } from '../@types/initiative';
import { filterEmpty } from './array'
import { getXAxisData } from '../views/reports/Heatmaps/utils'


export const currentQuarterDateRange = [
    startOfQuarter(startOfDay(new Date())),
    endOfQuarter(endOfDay(new Date()))
] as DateRange<Date>


export const currentThreeMonthRange = [
    startOfMonth(startOfDay(new Date())),
    endOfMonth(addMonths(new Date(), 2))
] as DateRange<Date>


export const currentMonthRange = [
    startOfMonth(startOfDay(new Date())),
    endOfMonth(endOfDay(new Date()))
] as DateRange<Date>

export const nextMonthRange = [
    startOfMonth(addMonths(startOfDay(new Date()), 1)),
    endOfMonth(addMonths(endOfDay(new Date()), 1))
] as DateRange<Date>


export const next3MonthRange = [
    startOfMonth(addMonths(startOfDay(new Date()), 1)),
    endOfMonth(addMonths(endOfDay(new Date()), 4))
] as DateRange<Date>

export const currentLifeTimeRange = [
    startOfMonth(addYears(startOfDay(new Date()), -20)),
    endOfMonth(addYears(endOfDay(new Date()), 30))
] as DateRange<Date>

export const reportsDefaultDateRange = [new Date(), add(new Date(), { years: 1 })] as DateRange<Date>

const parseDatesFns = [
    (i: string) => parseISO(i),
    (i: string) =>  new Date(Date.parse(i)),
    (i: string) => parse(i, 'yyyy-MM-dd', new Date()),
    (i: string) => parse(i, 'dd/MM/yyyy', new Date()),
]

export const toDateRange = (from: string, to: string): DateRange<Date> | null => {
    for (let i = 0; i < parseDatesFns.length; i++) {
        try {
            const From = parseDatesFns[i](from)
            const To = parseDatesFns[i](to)
            if (isValidDate(From) && isValidDate(To)) {
                if (From.getTime() > To.getTime()) {
                    return null
                }
                return [ From, To ]
            }
        } catch (_) {
        }
    }
    return null
}

export const isValidDate = (date: any) => {
    if (!date) {
        return false;
    }
    if (!(date instanceof Date)) {
        return false
    }
    if (isNaN(date.getTime())) {
        return false
    }
    /*if (date.toString() === 'Invalid Date') {
        return false
    }*/
    if (date.getFullYear() <= 1970) {
        return false;
    }
    return true;
}

export const splitDateIntoEqualIntervals = (startDate: Date, endDate: Date, numberOfIntervals: number): { start: Date; end: Date }[] => {
    const days = eachDayOfInterval({
        start: startDate,
        end: endDate,
    })
    return chunk(days, days.length / numberOfIntervals).map((i) => ({
        start: head(i) as Date,
        end: last(i) as Date,
    }))
}

export const parseFromToDateObject = <T> (item: Record<string, any>): T => ({
    ...item,
    From: parseISO(item?.From || item?.from as string),
    To: parseISO(item?.To || item?.to as string)
}) as T

export const parseImpactTiming = (impact: ImpactList, dateRange: DateRange<Date>|null|undefined): ImpactList => {
    const Timing = parseImpactTimingString(impact.Timing)

    if (dateRange) {
        Timing.Dates = Timing.Dates.filter((date) => dateInRange(date, dateRange))
        Timing.Ranges = Timing.Ranges.filter((range) => dateRangeInRange (range, dateRange))
    }
    const { From, To }  = impactTotalTiming(Timing)
    return {
        ...impact,
        Timing,
        From,
        To
    }
}

export const getWeeksInMonth = (date: Date): WeeksInMonth[] => {

    if (!date) {
        return []
    }

    const res = getCalendarWeeks(date, endOfMonth(date)).map((week) => {
        const fromDiff = week.from.getMonth() === date.getMonth()
        const toDiff = week.to.getMonth() === date.getMonth()
        if (!fromDiff && !toDiff) {
            return null
        }
        const from = fromDiff ? week.from : week.to
        const to = toDiff ? week.to : week.from

        if (isSameDay(from, to)) {
            return null
        }

        return {
            ...week,
            month: date.getMonth(),
            from,
            to,
            week: getWeekOfMonth(from, { weekStartsOn: 1 }),
        }
    })

    return filterEmpty(res) as WeeksInMonth[]
}

export const getCalendarWeeks = (startDate: Date | null, endDate: Date | null): WeeksInMonth[] => {
    if (!startDate || !endDate) {
        return []
    }

    return eachWeekOfInterval({
        start: startDate,
        end: endDate,
    }, { weekStartsOn: 1 }).map((week) => ({
        year: week.getFullYear(),
        month: week.getMonth(),
        week: getWeekOfMonth(week, { weekStartsOn: 1 }),
        from: startOfWeek(addMinutes(startOfDay(week), 10), { weekStartsOn: 1 }),
        to: endOfWeek(addMinutes(endOfDay(week), -10), { weekStartsOn: 1 })
    }))
}


export function getCalendarMonths(startDate: Date | null, endDate: Date | null): WeeksInMonth[] {

    let result: WeeksInMonth[] = []

    if (!startDate || !endDate) {
        return result
    }

    return eachMonthOfInterval({
        start: startDate,
        end: endDate,
    }).map((week) => ({
        year: week.getFullYear(),
        month: week.getMonth(),
        week: -1,
        from: startOfMonth(addMinutes(startOfDay(week), 10)),
        to: endOfMonth(addMinutes(endOfDay(week), -10))
    }))
}



export const impactTimingDates  = (timing: ImpactTiming): Date[] => [
        ...timing.Dates,
        ...timing.Ranges.map((range) => eachDayOfInterval({ start: range.From, end: range.To })).flat()
]


export const impactTotalTiming = (impactTiming: ImpactTiming): ImpactTimingRange => {
    const dates = impactTiming.Dates.map((date) => date.getTime())
    let From = Math.min(...dates) || 0
    let To = Math.max(...dates) || 0

    impactTiming.Ranges.forEach((range) => {
        if (range.From.getTime() < From || From === 0 ) {
            From = range.From.getTime()
        }
        if (range.To.getTime() > To || To === 0) {
            To = range.To.getTime()
        }
    })

    return { From: new Date(From), To: new Date(To) }
}

export const initiativeTotalTiming = (impacts: ImpactList[]): ImpactTimingRange  => {
    let From =  0
    let To =  0
    impacts
        .map((impact: ImpactList) => {
            const timing = parseImpactTimingString(impact.Timing)
            return impactTotalTiming(timing)
        })
        .forEach((range) => {
            if (range.From.getTime() < From || From === 0 ) {
                From = range.From.getTime()
            }
            if (range.To.getTime() > To || To === 0) {
                To = range.To.getTime()
            }
        })

    return { From: new Date(From), To: new Date(To) }
}

export const parseImpactTimingString = (timing: string | ImpactTiming | ImpactTimingResponse, widthDays: boolean = false): ImpactTiming => {
    const emptyTiming = {
        Ranges: [],
        Dates: []
    }
    try {
        const { Ranges, Dates } = isString(timing) ? JSON.parse(timing as string) : timing

        return {
            Dates: (Dates||[]).map((date: string) => isValidDate(date) ? date : parseISO(date)).filter(isValidDate),
            Ranges: (Ranges||[]).map((range: ImpactTimingRangeResponse) => {
                const from = (isValidDate(range.From) ? range.From : parseISO(range.From)) as Date
                const to = (isValidDate(range.To) ? range.To : parseISO(range.To)) as Date
                const From = addMinutes(startOfDay(from), 10)
                const To = addMinutes(endOfDay(to), -10)

                if (!isValidDate(From) || !isValidDate(To)) {
                    return null
                }

                const interval = { From, To }
                if (!widthDays) {
                    return interval
                }
                return {
                    ...interval,
                    Days: eachDayOfInterval({ start: interval.From as Date, end: interval.To as Date })
                }
            }).filter((i: any) => i )
        }
    } catch (e) {
        console.error(e)
    }
    return emptyTiming
}

export const impactTimingServerFormat = (timing: ImpactTiming): ImpactTimingResponse => ({
    Dates: timing.Dates.map((i) => toServerTime(i) as string),
    Ranges: timing.Ranges.map((range) => ({ From: toServerTime(range.From) as string, To: toServerTime(range.To) as string }))
})

export const dayRange = (date: Date, distance: number = 10): ImpactTimingRange => ({ From: addMinutes(startOfDay(date), distance), To: addMinutes(endOfDay(date), -distance) })

export const dateInRange = (date: Date, dateRange: DateRange<Date>): boolean => {
    const range = { start:  addMinutes(startOfDay(date), 10), end: addMinutes(endOfDay(date), -10) }
    try {
        return areIntervalsOverlapping(
            { start: dateRange[0] as Date, end: dateRange[1] as Date },
            range,
            { inclusive: true }
        )
    } catch (error) {
        reportError(error)
        return false
    }
}


export const dateRangeInRange =  (range: ImpactTimingRange, dateRange: DateRange<Date>): boolean => {
    try {
        return areIntervalsOverlapping(
            { start: range.From as Date, end: range.To as Date },
            { start: dateRange[0] as Date, end: dateRange[1] as Date },
            { inclusive: true }
        )
    } catch (error) {
        return false
    }

}

export const mergeWeeksInMonth = (weeks: WeeksInMonth[]): DateRange<Date>[] => {
    const ranges = weeks.map((week) => [ week.from, week.to ]) as [ Date, Date ][]
    ranges.sort((a, b) => a[0].getTime() - b[0].getTime())

    const skipIndex: number[] = []
    const res =  ranges.map((range, index) => {
        let [ from, to ] = range as [ Date, Date ]
        if (skipIndex.includes(index)) {
            return null
        }
        ranges.slice(index + 1).forEach((r, iindex) => {
            if (dateInRange(r[0], [ from, addDays(to, 3) ])) {
                to = r[1] as Date
                skipIndex.push(index + 1 + iindex)
            }
        })
        return [ from, to ]
    }).filter((i) => i)
    return res as DateRange<Date>[]
}

export const isInWeekInMonthRange = (impactRange: ImpactWeeksInMonth[], week: ImpactWeeksInMonth) => (impactRange||[]).some(
    (range: ImpactWeeksInMonth) => range.year === week.year && range.month === week.month && range.week === week.week
)

export const impactsInMonthRange = (impactRange: ImpactWeeksInMonth[], month: WeeksInMonth,): boolean => (impactRange||[]).some(
    (range: Record<string, any>) => range.year === month.year && range.month === month.month
) || false


export const impactRangeIn = (impactRange: ImpactWeeksInMonth[], month: WeeksInMonth,): ImpactWeeksInMonth[] => (impactRange||[]).filter(
    (range: Record<string, any>) => range.year === month.year && range.month === month.month
) || false

export const filterWeekInMonth = (range1: ImpactWeeksInMonth[], range2: ImpactWeeksInMonth[]): ImpactWeeksInMonth[] =>
    range1.filter((i) => isInWeekInMonthRange(range2, i))


export const impactsInWeekInMonthRange = (i: WeeksInMonth, items: Record<string, any>[]): Record<string, any>[] => items.filter(
    (impact: Record<string, any>) => impact.range?.some(
        (range: Record<string, any>) => range.year === i.year && range.month === i.month && range.week === i.week
    ) || false
)

export interface GraphDateRangeResolution {
    From: Date
    To: Date
    formatted: string
}

export const dateRangeGraphResolution = (dateRange: DateRange<Date>): GraphDateRangeResolution[] => {
    let monthDiff = differenceInMonths(dateRange[1] as Date, dateRange[0] as Date) + 1;

    if (monthDiff < 0) {
        monthDiff = -monthDiff
    }
    if (monthDiff > 3) {
        return eachMonthOfInterval({ start: dateRange[0] as Date,  end: dateRange[1] as Date })
            .map((date) => {
                const start = startOfMonth(date)
                const end = endOfMonth(date)
                return { From: start, To: end, formatted: formatDate(start, 'MMM `yy') } as GraphDateRangeResolution
            })
    }

    return eachWeekOfInterval(
        { start: dateRange[0] as Date, end: dateRange[1] as Date },
        { weekStartsOn: 1 }
    ).map((week) => {
        const start = startOfWeek(week, { weekStartsOn: 1 })
        const end = endOfWeek(week, { weekStartsOn: 1 })
        return {
            From: start,
            To: end,
            formatted: `${formatDate(start, 'd MMM')}-${formatDate(end, 'd MMM')} ${formatDate(week, 'y')}`
        } as GraphDateRangeResolution
    })

}

export const dateRangeInImpactTiming = (timing: ImpactTiming, dateRange: DateRange<Date> | GraphDateRangeResolution): boolean => {
    let start = new Date()
    let end = new Date()
    if (isArray(dateRange)) {
        start = (dateRange as DateRange<Date>)[0] as Date
        end = (dateRange as DateRange<Date>)[1] as Date
    } else {
        start = dateRange.From
        end = dateRange.To
    }

    if (timing.Dates.some((date) => dateInRange(date, [start, end]))) {
        return true
    }
    return timing.Ranges.some((range) => dateRangeInRange (range, [start, end]))
}

export const impactsInDateRange = (impacts: { Timing: ImpactTiming }[], dateRange: DateRange<Date>): boolean =>
    (impacts || []).some((i) => dateRangeInImpactTiming(i.Timing, dateRange))


export const splitByWeeksTiming = (timing: ImpactTiming): WeeksInMonth[] =>
    [
        ...(timing.Dates || []).map((date: Date) => {
            return splitByWeeks(
                addMinutes(startOfDay(date), 10),
                addMinutes(endOfDay(date), -10)
            )
        }),
        ...(timing.Ranges || []).map((range: ImpactTimingRange) => splitByWeeks(
            addMinutes(startOfDay(range.From), 10),
            addMinutes(endOfDay(range.To), -10))
        ),
    ].flat()


export const splitByWeeks = (startDate: Date, endDate: Date): WeeksInMonth[] => {
    let result: WeeksInMonth[] = []

    if (!startDate || !endDate) {
        return result
    }

    let dateStart = startOfWeek(addMinutes(startOfDay(startDate), 10), { weekStartsOn: 1 })
    if (dateStart.getMonth() !== startDate.getMonth()) {
        dateStart = startOfMonth(addMinutes(startOfDay(startDate), 10))
    }

    const res = []

    while (dateStart.getTime() < endDate.getTime()) {
        const endWeek = addMinutes(endOfDay(endOfWeek(dateStart, { weekStartsOn: 1 })), -10)

        let weekBreaks = dateStart.getMonth() !== endWeek.getMonth()
        // && dateStart.getFullYear() === endWeek.getFullYear()

        const _w = getWeekOfMonth(dateStart, { weekStartsOn: 1 })
        res.push({
            year: dateStart.getFullYear(),
            month: dateStart.getMonth(),
            week: _w,
            from: dateStart,
            id: `${dateStart.getFullYear()}${dateStart.getMonth()}${_w}`,
            to: weekBreaks ? addMinutes(endOfDay(endOfMonth(dateStart)), -15) : endWeek,
        })

        if (weekBreaks) {
            const _ww = getWeekOfMonth(endWeek, { weekStartsOn: 1 })
            res.push({
                year: endWeek.getFullYear(),
                month: endWeek.getMonth(),
                week: _ww,
                from: startOfDay(startOfMonth(endWeek)),
                id: `${endWeek.getFullYear()}${endWeek.getMonth()}${_ww}`,
                to: endWeek,
            })
        }
        dateStart = startOfWeek(addWeeks(dateStart, 1), { weekStartsOn: 1 })
    }
    return res
}
